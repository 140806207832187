/* eslint-disable react/jsx-pascal-case */
import React, {useEffect, useState} from 'react'
import Select, { components, createFilter } from 'react-select'
import { enum2label, flattenGroupOptions2Options, flattenObject2Array, object2options, objectList2options, optionsFilter } from './functions'
import data from './../../data/_data';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt, faComment, faCommentDots } from "@fortawesome/free-solid-svg-icons";
import businessobject_data from './../../data/businessobject_data';
import PopUp from './PopUp';
import Administration_Benutzer from '../Administration/Administration_Benutzer';
import Suche from './../Suche';
import Pflegen from './../Pflegen/Pflegen';
import Administration_SpecificBenutzer from './../Administration/Administration_SpecifcBenutzer';
import { semanticCheck } from './functions';
import { options2values } from './functions';
import { MultiSelect, SingleSelect } from './Kopfzeile';
import {objectList2AttrList, sortList} from './functions';
import message_data from '../../data/message_data';


/**
 * Kombiniert einige Elemente zur Wiederverwendung im Contentbody der Pflegenseiten
 * 
 * @author DHR
 */

export const TooltipInfo = ({name, classNameExtend, noLabel=false}) => {
    name = (name?.includes('Id') ? name?.replace('Id', '') : name?.toLowerCase())
    // console.log("### TooltipInfo: ", name)
    if (!name || !message_data.tooltip[name]){return null}

    const [isHovered, setIsHovered] = useState(false);

    function handleMouseEnter() {
        setTimeout(() => {
            setIsHovered(true);
        }, 1000);
    }

    function handleMouseLeave() {
        setIsHovered(false);
    }

    return (
        <div key={name + '-tooltiplabel'} className={"tooltip tooltip-icon" + (classNameExtend ? ' '+classNameExtend : '' ) + (noLabel ? ' tooltip-allover' : '' )} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {(!noLabel ? 'i' : '') }
            {isHovered && (
                <span className="tooltiptext">{message_data.tooltip[name]}</span>
            )}
        </div>
    );
}

/**
 * Generiert je nach type ein Label mit Input mit dem entsprechendem Typ
 * 
 * @param {String} type - Typ des Inputfeldes
 * @param {int} passKey - key zur eindeutigen Identifikation des Elements
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} value - wert des Inputs
 * @param {String} name - interner name des Inputs
 * @param {String} presentation - Anzeigename des Inputfeldes
 * @param {Function} setValue - funktion zum Setzen des Wertes
 * @param {boolean} asNumber - gibt an, dass der Wert als Zahl interpretiert werden soll
 * @param {int} maxChar - maximale Anzahl an Zeichen
 * @param {boolean} isInvalid - gibt an, ob das Element als ungültig markiert werden soll    
 * @returns {HTML} Inputfeld entsprechend des Typ
 */
export const InputByType = ({type, passKey, readOnly, value, name, presentation, setValue, asNumber, maxChar, isInvalid, notNull}) => {
        // useEffect(() => {if (semanticCheckObj && setSemanticFeedback){
        //     setSemanticFeedback(semanticCheck(value, semanticCompareValue, semanticCheckObj.function))
        // }  }, [value, semanticCompareValue]);
    switch (type){
        case data.inputTypes.standard.PLAIN:
            return <div className="label" key={passKey} >{presentation}<div>{value}</div></div>
        case data.inputTypes.standard.CHECKBOX:
            return <div className={"label checkbox-label"+(isInvalid ? ' invalid' : '')} key={passKey} >{presentation}<TooltipInfo name={name} /><input type='checkbox' name={name} checked={!!value} disabled={readOnly} onChange={e => setValue(!value)} /></div>
        case data.inputTypes.standard.TEXTAREA:
            return <div className="label area" key={passKey} >{presentation}{notNull ? <span className="required-label">*</span> : null}<TooltipInfo name={name} /><textarea type={type} maxLength={maxChar ? maxChar : "900"} className={''+(isInvalid ? ' invalid' : '')+(notNull ? ' required' : '')} name={name}  disabled={readOnly} value={value} onChange={e => setValue(e.target.value)} /></div>
        case data.inputTypes.standard.SHORT:
            return <TextInput key={passKey} clsNameExt='short' maxChar={maxChar} readOnly={readOnly} value={value} name={name} notNull={notNull} presentation={presentation} setValue={setValue} isInvalid={isInvalid} asNumber={asNumber} />
        case data.inputTypes.standard.NUMBER:
            return <div className="label" key={passKey} >{presentation}{notNull ? <span className="required-label">*</span> : null}<TooltipInfo name={name} /><input type={type} className={''+(isInvalid ? ' invalid' : '')+(notNull ? ' required' : '')} name={name} disabled={readOnly} value={value} onChange={e => setValue(e.target.valueAsNumber)} /></div>
        case data.inputTypes.standard.DATE:
            return <div className="label" key={passKey} >{presentation}{notNull ? <span className="required-label">*</span> : null}<TooltipInfo name={name} /><input type={type} className={''+(isInvalid ? ' invalid' : '')+(notNull ? ' required' : '')} name={name} disabled={readOnly} value={value} onChange={e => setValue(e.target.value)} /></div>
        case data.inputTypes.standard.MULTIPLE:
            return <MultipleInput key={passKey} readOnly={readOnly} value={value} name={name} presentation={presentation} setValue={setValue} invalidObject={isInvalid} />
        case data.inputTypes.NONE:
            return null
        default:
            if (typeof presentation === 'object'){return <div className="label" key={passKey} >{name}<div>{value}</div></div>}
            return <TextInput key={passKey} readOnly={readOnly} value={value} name={name} notNull={notNull} presentation={presentation} setValue={setValue} asNumber={asNumber} isInvalid={isInvalid} />
    }
}

/**
 * Generiert ein einfaches Textinputfeld mit Label
 * 
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} value - wert des Inputs
 * @param {String} clsNameExt - String der als zusätzliche CSS-Klasse verwendet wird
 * @param {String} name - interner name des Inputs
 * @param {String} presentation - Anzeigename des Inputfeldes
 * @param {Function} setValue - funktion zum Setzen des Wertes
 * @param {boolean} asNumber - gibt an, dass der Wert als Zahl interpretiert werden soll
 * @param {int} maxChar - maximale Anzahl an Zeichen
 * @param {boolean} isInvalid - gibt an, ob das Element als ungültig markiert werden soll      
 * @returns {HTML} Textinputfeld mit Label
 */
export const TextInput = ({ readOnly, value, clsNameExt, name, presentation, setValue, maxChar, asNumber, isInvalid, notNull, autoComplete = false}) => {
    // const [matchRegex, setMatchRegex] = useState(true)
    const [isHovered, setIsHovered] = useState(false);

    function onInputChange(v){
        if (maxChar){
            v = v.substring(0, maxChar).toUpperCase()
        }
        if (asNumber){
            if (!isNaN(v)){
                setValue(Number(v))
                if (v === ''){setValue('')}
            }
        } else {
            setValue(v)
        }
    }

    function handleMouseEnter() {
        setTimeout(() => {
            setIsHovered(true);
        }, 1000);
    }

    function handleMouseLeave() {
        setIsHovered(false);
    }

    return (
        <div className={(clsNameExt ? "label " + clsNameExt : "label")}>
            {presentation}{notNull ? <span className="required-label">*</span> : null}<TooltipInfo name={name} />
            <div className="tooltip" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <input
                    type='text'
                    className={'' + (isInvalid ? ' invalid' : '') + (notNull ? ' required' : '')}
                    name={name}
                    value={value}
                    disabled={readOnly}
                    aria-autocomplete={autoComplete ? name : "none"}
                    autoComplete={autoComplete ? name : "off"}
                    onChange={e => onInputChange(e.target.value)}
                />
                {(isHovered && value) && (
                    <span className="tooltiptext">{value}</span>
                )}
            </div>
        </div>
    );
};

/**
 * Generiert mehrere Textinputfelder mit einem gemeinsamen Label
 * Möglichkeit des Hinzufügen und Löschen von Inputfeldern
 * die Values der Felder werden in einer Liste verwaltet
 * 
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} value - wert des Inputs
 * @param {String} name - interner name des Inputs
 * @param {String} presentation - Anzeigename des Inputfeldes
 * @param {Function} setValue - funktion zum Setzen des Wertes
 * @param {Object} invalidObject - gibt an, welches der Elemente als ungültig markiert werden soll
 * @returns {HTML} Mehrere Textinputfelder mit einem gemeinsamen Label
 */
export const MultipleInput = ({readOnly, value=[], name, presentation, setValue, invalidObject}) => {

    function onAdd(){
        if (!value.includes("")){value.push("")}
        setValue(value)
    }

    function set(v, i){
        value[i] = v
        setValue(value)
    }

    function onRemove(i){
        value.splice(i, 1)
        setValue(value)
    }

    return(
        <div className="label multi" >{presentation}<TooltipInfo name={name} />
            <div className="multi-inputs">
                {value && value.map((v, i) => (
                    <div className="multi-inputs-item" key={i}>
                        <input type='text' className={(invalidObject && invalidObject[i] ? ' invalid' : '')} name={name} disabled={readOnly} value={v} onChange={e => set(e.target.value, i)} />
                        <button className="inline" disabled={readOnly} onClick={() => (onRemove(i))}>-</button>
                    </div>
                ))}
                <button className="small" disabled={readOnly || value?.includes("") || (invalidObject ? Object.values(invalidObject)?.includes(true) : false)} onClick={() => (onAdd())}>+</button>
            </div>
        </div>
    )
}

/**
 * Generiert eine Icon welches beim Click ein popUp mit einer Textarea und einem Label öffnet
 * 
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} value - wert des Inputs
 * @param {String} name - interner name des Inputs
 * @param {String} title - Titel des PopUps
 * @param {String} presentation - Anzeigename des Inputfeldes
 * @param {Function} setValue - funktion zum Setzen des Wertes
 * @returns {HTML} Icon und PopUp mit Textarea
 */
export const PopUpTextarea = ({readOnly, value, name, title, presentation, setValue}) => {
    const [showPopUp, setShowPopUp] = useState(false)
    const [textValue, setTextValue] = useState(value)

    return (
        <div className='inner-contents popup-textarea'>
            {(showPopUp ? 
                <PopUp title={(title ? title : presentation)}
                    name={name}
                    content={<div className='popup-input-details'>
                        <div className="label area">{presentation}<textarea type='textarea' name={name} maxLength="500" disabled={readOnly} value={textValue} onChange={e => setTextValue(e.target.value)} /></div>
                    </div>} 
                    close={()=>{setValue(textValue); setShowPopUp(false)}} closeText={readOnly ? 'Schließen':'Speichern'} abort={()=>{setTextValue(value);setShowPopUp(false)}}  /> 
            : null)}
            <div className="label symbol" >
                <div className='popup-input-wrapper'>
                    <div className='clickable' onClick={() => setShowPopUp(true)}><FontAwesomeIcon id="commentIcon" className='faIcon' size="2x" icon={faCommentDots} /></div>
                </div>
            </div>
        </div>
    )
}

/**
 * Generiert ein Icon welches beim Click ein PopUp zum Dateien Hochladen öffnet
 * Zur Datei können weitere Beschreibungen ergänzt werden
 * Es ist möglich, mehrere Dateien zuverwalten
 * Es ist möglich ein Vorschaubild der Datei anzuzeigen
 * 
 * @param {boolean} picture - gibt an, ob das Element ein Bild ist
 * @param {boolean} multiple - gibt an, ob mehrere Dateien verwaltet werden können
 * @param {Function} downloadFile - Funktion zum herunterladen der Datei
 * @param {Object} requestedFile - heruntergeladene Datei
 * @param {Object} dateitypOptions - Dropdownoptionen für React-Select für den Dateikontext
 * @param {String} presentation - Label des Inputs
 * @param {Object} defaultObject - Objekt in welchem die Datei und die Kontextinformationen gespeichert werden
 * @param {Object} presentationObject - Label der Dateiinputfelder
 * @param {String} field - alt des Bildes
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} value - Wert des Inputs
 * @param {Function} setValue - Funktion zum Setzen des Wertes
 * @param {String} size - Parameter zur Änderung der Größe des Icons
 * @returns {HTML} Icon mit PopUp um Dateien mit Konextdaten (Textinputfeldern) Hoch- und Runterzuladen
 */
export const FileInput = ({ picture, acceptedType, multiple, downloadFile, requestedFile, dateitypOptions, presentation, defaultObject = {...businessobject_data.datei.default}, presentationObject = businessobject_data.datei.fields, field, readOnly, value, setValue, size}) => {
    const [name, setName] = useState((multiple ? "" : ((value&&value.name) ? value.name : "")))
    const [beschreibung, setBeschreibung] = useState((multiple ? "" : ((value&&value.beschreibung) ? value.beschreibung : "")))
    const [dateityp, setDateityp] = useState((multiple ? "" : ((value&&value.dateityp) ? value.dateityp : "")))
    const [picPreview, setPicPreview] = useState("")
    const [showPopUp, setShowPopUp] = useState(false)

    if (multiple && !value){value=[];}

    // Preview der Datei laden
    const reader = new FileReader();
    reader.onload = (evt) => {
        setPicPreview(evt.target.result);
    }

    if (value && picture && !multiple && value.dateiData && !('data' in value.dateiData || 'filename' in value.dateiData)){
        reader.readAsDataURL(value.dateiData);
    }
    if (value && picture && !multiple && value.dateiData && ('data' in value.dateiData || 'filename' in value.dateiData) && value.dateiData.data && value.dateiData.filename){
        reader.readAsDataURL(value.dateiData.data);
    }

    // heruntergeladenen Datei dem Browser übergeben oder herunterladen
    function handleFileDownload(id){
        if (requestedFile && requestedFile.id === id && requestedFile.data && requestedFile.filename){
            const url = URL.createObjectURL(requestedFile.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = requestedFile.filename;
            link.click();
        } else {
            downloadFile(id)
        }
    }

    // Datei dem Browser übergeben sofern heruntergeladen
    useEffect(() => {
        if (requestedFile && requestedFile.data && requestedFile.filename){
            const url = URL.createObjectURL(requestedFile.data);
            const link = document.createElement('a');
            link.href = url;
            link.download = requestedFile.filename;
            link.click();
        }
    }, [requestedFile])

    // Datei und Kontext zusammenfügen und als Value setzen
    function handleFileUpload(e){
        setShowPopUp(false)
        if (multiple){
            let tValue = [...value]
            let tValueO = {...tValue[tValue.length-1]}
            tValueO.dateiData = e.target.files[0]
            tValue[tValue.length-2] = tValueO
            tValue.pop()
            setValue([...tValue])
        } else {
            let tValue = {...value}
            tValue.dateiData = e.target.files[0]
            setValue({...tValue})

            if (picture){reader.readAsDataURL(e.target.files[0]);}
        }
    }

    // Kontextinformationen setzen
    function set(){
        if (multiple){
            let tValue = [...value]
            let temp = {...defaultObject}
            temp.name = name
            temp.beschreibung = beschreibung
            temp.dateityp = (picture ? 'PROFILFOTO' : dateityp)
            tValue.push(temp)
            setValue(tValue)
        } else {
            let temp = {...defaultObject}
            temp.name = name
            temp.beschreibung = beschreibung
            temp.dateityp = (picture ? 'PROFILFOTO' : dateityp)
            setValue(temp)
        }
    }

    // Kontextinformationen und Datei löschen
    function onRemove(i){
        if (multiple){
            let tValue = [...value]
            tValue.splice(i,1)
            setValue(tValue)
        } else {
            setValue({...defaultObject})
        }
    }

    // PopUp schließen
    function onAbort(){
        setShowPopUp(false)
    }

    return (
        <div className='file-input inner-contents'>
                {(showPopUp ? 
                    <PopUp title='Datei Hochladen' text={presentation}
                        content={<div className='popup-input-details'>
                            {field === "profilbild" && (
                                <div className='info'>
                                    {message_data.info.pflegen.picture}
                                </div>
                            )}
                            <TextInput value={name} readOnly={readOnly} presentation={presentationObject.name} setValue={v => setName(v)} notNull={true} />  
                            <TextInput value={beschreibung} readOnly={readOnly} presentation={presentationObject.beschreibung} setValue={v => setBeschreibung(v)} />  
                            {field === "profilbild" ?                                     
                                <TextInput value="Profilfoto" readOnly={true} presentation={presentationObject.dateityp} setValue={v => setDateityp(v)}/>:                               
                                <DropdownInput presentation={presentationObject.dateityp} value={dateityp} setValue={v => setDateityp(v)} readOnly={readOnly} options={dateitypOptions} />
                            }
                        </div>} 
                        abort={onAbort} customClose={<label className={"button" + (readOnly ? ' label-disabled' : '')} disabled={readOnly} onClick={() => set()}>Datei auswählen<input type='file' style={{display: 'none'}} onChange={e => handleFileUpload(e)} disabled={readOnly} accept={(picture ? "image/*" : (acceptedType ? acceptedType : "*"))} /></label>} /> 
                : null)}
                <div className="label" >{presentation}
                    <div className='popup-input-wrapper'>
                        <div className={'clickable'+(picture ? ' picture-preview' : '')} onClick={() => setShowPopUp(true)} disabled={readOnly}>{(picture && !multiple && value && value.dateiData && picPreview) ? <a href={((value && value.dateiData && value.dateiData.data) ? URL.createObjectURL(value.dateiData.data) : null)} className={picture ? "picture-download" : ""} download={value.dateiData.filename} ><img src={picPreview} width="125px" alt={field} /></a> : <FontAwesomeIcon id="uploadIcon" className={'faIcon' + (readOnly ? ' fa-disabled' : '')} size={(size?size:'3x')} icon={faCloudUploadAlt} />}</div>
                        {(!multiple && value && value.dateiData) && <div className='file-input-list'><div className='file-input-list-item'><a href={((value && value.dateiData && value.dateiData.data) ? URL.createObjectURL(value.dateiData.data) : null)} download={value.dateiData.filename} >{value.name}</a><button className="micro" disabled={readOnly} onClick={() => (onRemove())}>-</button></div></div> }
                        {(multiple&&value) && <div className='file-input-list'>
                            {value.map((k, i) => (
                                <div className='file-input-list-item' key={i} ><button className="link" onClick={() => handleFileDownload(k.id)} >{k.name}</button> <button className="inline" disabled={readOnly} onClick={() => (onRemove(i))}>-</button></div>
                            ))}
                        </div>}
                    </div>
                </div>
            </div>
        )
}

/**
 * Custom Option mit Checkbox und Label für React-Select
 * 
 * @param {*} props - alle Parameter von React-Select
 * @returns {HTML} Option für React-Select
 */
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

/**
 * Generiert ein Dropdowninput mit React-Select
 * Es ist möglich, mehrere Einträge auszuwählen oder neue zu erstellen
 * 
 * @param {String} reactSelectKey - key des React-Select Elements
 * @param {boolean} multiSelect - gibt an, ob mehrere Elemente ausgewählt werden können
 * @param {Object} value - wert des Inputs
 * @param {Function} onCreate - Funktion um eine neue Option zu erstellen
 * @param {String} presentation - Label des Inputs
 * @param {boolean} searchable - gibt an, ob die Optionen durchsuchbar sind
 * @param {String} name - interner Name des Inputs
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {Function} setValue - Funktion zum Setzen des Wertes
 * @param {Object} options - Auswahl Optionen des Dropdown
 * @param {boolean} table - gibt an, ob die Ausgewählten Elemente als tabelle dargestellt werden sollen
 * @returns {HTML} Dropdowninput
 */
export const DropdownInput = ({reactSelectKey, multiSelect, value, onCreate, presentation, searchable=false, clearable = true, name, readOnly, setValue, options, table=true, notNull}) => {
    if (!value && multiSelect){value = []}
    const [createValue, setCreateValue] = useState("")

    //hook vars for filter config
    const [ignoreCase, setIgnoreCase] = useState(true)
    const [ignoreAccent, setIgnoreAccent] = useState(true)
    const [trim, setTrim] = useState(true)


    const reactSelectId = reactSelectKey+"_dropdown_"+name+"_1"
    const [isHovered, setIsHovered] = useState(false);
    const [isDropdownOpened, setIsDropdownOpened] = useState(false);

    const filterConfig = {
        ignoreCase,
        ignoreAccent,
        trim,
        matchFrom : 'start'
    }

    function onRemove(i){
        value.splice(i, 1)
        setValue(value)
    }

    function onChange(v){
        if (multiSelect && !table){
            setValue(options2values(v))
        } else if (multiSelect && table){
            setValue([...value, v.value])
        } else {
            setValue(v ? v.value : "")
        }
    }
    function displayValue(){
        if(!multiSelect) {
            return optionsFilter(options, 'value', value) 
        } 
        else {
            return options.filter(obj => value.includes(obj.value))
        }
    }
    
    if (name !== 'niveau' && options){
        options = options.sort((a, b) => (a.label > b.label) ? 1 : -1)
    }

    function handleMouseEnter() {
        setTimeout(() => {
            setIsHovered(true);
        }, 1000);
    }

    function handleMouseLeave() {
        setIsHovered(false);
    }

    const handleDropdownOpen = () => {
        setIsDropdownOpened(true);
    };

    const handleDropdownClose = () => {
        setIsDropdownOpened(false);
    };
    // if (reactSelectKey){
    //     const reactSelectElem = document.getElementById(reactSelectId)
    //     let rect;
    //     if (reactSelectElem){
    //         rect = reactSelectElem.getBoundingClientRect()
    //     }
    // }

    //styles={{singleValue: () => ({position: 'absolute'})}}
    return (
        <div className='dropdown-input'>
            <div className="label">{presentation}{notNull ? <span className="required-label">*</span> : null}<TooltipInfo name={name} />
                {(!onCreate ?
                <div className={isDropdownOpened ? "" : 'tooltip'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    {(multiSelect && !table) ?
                        <Select className={"dropdown"+(notNull ? ' required' : '')} id={reactSelectId} classNamePrefix="react-select" isMulti={true} controlShouldRenderValue={false} value={flattenGroupOptions2Options(options).filter(obj => value.includes(obj.value))} isClearable={clearable} hideSelectedOptions={false} closeMenuOnSelect={false} components={{Option}} isSearchable={(!!onCreate||searchable)} name={name} isDisabled={readOnly} options={options} noOptionsMessage={() => 'Keine Daten vom Server empfangen'} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => onChange(s)} onInputChange={(c) => {if (c){setCreateValue(c)}}} onMenuOpen={handleDropdownOpen}
                        onMenuClose={handleDropdownClose}/> //filterOption={createFilter(filterConfig)}
                    : <Select className={"dropdown"+(notNull ? ' required' : '')} id={reactSelectId} classNamePrefix="react-select" value={(!multiSelect ? optionsFilter(options, 'value', value) :null)} isSearchable={(!!onCreate||searchable)} name={name} isDisabled={readOnly} isClearable={clearable} options={(multiSelect ? options.filter(opt => !value.includes(opt.value)) : options)} noOptionsMessage={() => 'Keine Daten vom Server empfangen'} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => onChange(s)} onInputChange={(c) => {if (c){setCreateValue(c)}}} onMenuOpen={handleDropdownOpen} 
                    onMenuClose={handleDropdownClose} />     //filterOption={createFilter(filterConfig)}        
                    }
                    {(isHovered && !isDropdownOpened &&  (typeof value !== "undefined") && (value !== "") && (value !== 0)) &&
                        <span className="tooltiptext">{typeof value === "number" ? optionsFilter(options, 'value', value).map(item => item.label) : enum2label(value, name)}</span>
                    }
                    </div>
                : <div className="dropdown-create-wrapper">
                    {( (multiSelect && !table) ?
                        <Select className={"dropdown"+(notNull ? ' required' : '')} id={reactSelectId} classNamePrefix="react-select" isMulti={true} controlShouldRenderValue={false} value={flattenGroupOptions2Options(options).filter(obj => value.includes(obj.value))} isClearable={clearable} hideSelectedOptions={false} closeMenuOnSelect={false} components={{Option}} isSearchable={(!!onCreate||searchable)} filterOption={createFilter(filterConfig)}  name={name} isDisabled={readOnly} options={options} noOptionsMessage={() => 'Keine Daten vom Server empfangen'} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => onChange(s)} onInputChange={(c) => {if (c){setCreateValue(c)}}} />
                    : <Select className={"dropdown"+(notNull ? ' required' : '')} id={reactSelectId} classNamePrefix="react-select" value={(!multiSelect ? optionsFilter(options, 'value', value) :null)} isSearchable={(!!onCreate||searchable)} filterOption={createFilter(filterConfig)} name={name} isDisabled={readOnly} isClearable={clearable} options={(multiSelect ? options.filter(opt => !value.includes(opt.value)) : options)} noOptionsMessage={() => 'Keine Daten vom Server empfangen'} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => onChange(s)} onInputChange={(c) => {if (c){setCreateValue(c)}}} />
                    )}
                    <button className="inline" onClick={() => {onCreate(createValue)}} disabled={(createValue.length < 3 || typeof value === 'number')}>+</button>
                  </div>
                )}
            </div>
            {(multiSelect && table) && <div className='table'>
                {value.map((v, i) => (<div className='table-row' key={i}><input type='text' name={options.find(o => o.value === v).label} value={options.find(o => o.value === v).label} disabled={clearable} /><button className="inline" onClick={() => {onRemove(i)}}>-</button></div>))}
            </div>}
        </div> 
    )
}

/**
 * Generiert einen Input zur Eingabe mehrerer Werte eines Objektes
 * Es ist möglich, mehrere Objekte zu erstellen
 * 
 * @param {Object} value - wert des Inputs
 * @param {boolean} create - gibt an, ob neue Elemente erstellt werden dürfen
 * @param {Object} defaultObject - Defaulteinträge des zu erstellenden Objektes
 * @param {Object} presentationObject - Label der Inputs eines Objektes
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {Function} setValue - Funktion zum Setzen des Wertes
 * @param {Object} optionsObject - Auswahl Optionen eines Dropdown, wenn ein Input des Objektes durch ein Dropdown dargestellt werden soll
 * @param {Object} invalidObject - gibt an, welches der Inputs als ungültig markiert werden soll
 * @returns {HTML} Input zur Eingabe mehrerer Werte eines Objektes
 */
export const InLineInput = ({ presentation, name, create, readOnly, setValue, value=[], defaultObject, presentationObject, optionsObject, invalidObject}) => {

    if(!create){value = [value]}
    if(!name)(name = presentation)

    function set(k, v, i){
        if(!create){
            setValue({...value[0], [k]:v})
        } else {
            value[i] = {...value[i], [k]:v}
            setValue(value)
        }
    }

    function onAdd(){
        value.push({...defaultObject})
        setValue(value)
    }

    function onRemove(i){
        value.splice(i, 1)
        setValue(value)
    }
    return (
        <div className='inline-input'>
            <div className="label" id="inline-input-presentation">{presentation}<TooltipInfo name={name} /></div>
            <div className='inline-input-element-container'>
            {value.map((o, i) => (
                <div className='inline-input-element' key={i}>
                    {Object.keys(presentationObject).map((k, j) => {
                        if (typeof presentationObject[k] === "object"){
                            return ( 
                                <div className="input-group" key={j}>
                                    {Object.keys(presentationObject[k]).map((gk, gj) => {
                                            if (businessobject_data.types[gk]?.type === data.inputTypes.standard.TEXTAREA){
                                                return <PopUpTextarea key={gj} readOnly={(businessobject_data.types[gk].readOnly ? true : readOnly)} value={o[gk]} name={gk} title={presentationObject[gk]} setValue={v => set(gk,v,i)}  />
                                            } else {
                                                return <InputByType key={gj} type={businessobject_data.types[gk]?.type || 'string'} isInvalid={(invalidObject && invalidObject[i] && invalidObject[i][gk])} asNumber={businessobject_data.types[gk]?.asNumber} readOnly={(businessobject_data.types[gk]?.readOnly ? true : readOnly)} value={o[gk]} name={gk} presentation={presentationObject[k][gk]} setValue={v => set(gk,v,i)} /> 
                                            }
                                    })}
                                </div>
                            )
                        }
                        if (businessobject_data.types[k]){
                            if (businessobject_data.types[k].type === data.inputTypes.advanced.DROPDOWN){
                                return <DropdownInput key={j} readOnly={(businessobject_data.types[k].readOnly ? true : readOnly)} name={k} value={o[k]} presentation={presentationObject[k]} setValue={v => set(k,v,i)} options={optionsObject[k]} searchable={(businessobject_data.types[k].searchable ? true : false)} notNull={businessobject_data.types[k].notnull} />
                            } 
                            if (businessobject_data.types[k].type === data.inputTypes.advanced.FILE){
                                return <FileInput key={j} presentation={presentationObject[k]} field={k} readOnly={(businessobject_data.types[k].readOnly ? true : readOnly)} value={o[k]} setValue={v => set(k, v, i)} dateitypOptions={optionsObject.dateityp} />
                            } 
                            if (businessobject_data.types[k].type === data.inputTypes.standard.TEXTAREA){
                                return <PopUpTextarea key={j} readOnly={(businessobject_data.types[k].readOnly ? true : readOnly)} value={o[k]} name={k} title={presentationObject[k]} setValue={v => set(k,v,i)}  />
                            } else {
                                return <InputByType key={j} type={businessobject_data.types[k].type} isInvalid={(invalidObject && invalidObject[i] && invalidObject[i][k])} asNumber={businessobject_data.types[k].asNumber} maxChar={(businessobject_data.types[k] ? businessobject_data.types[k].maxChar : null)} readOnly={(businessobject_data.types[k].readOnly ? true : readOnly)} value={o[k]} name={k} presentation={presentationObject[k]} setValue={v => set(k,v,i)} notNull={businessobject_data.types[k].notnull} /> 
                            }
                        } else {
                            return <TextInput key={j} readOnly={businessobject_data.types[k] ? (businessobject_data.types[k].readOnly ? true : readOnly) : readOnly} isInvalid={(invalidObject && invalidObject[i] && invalidObject[i][k])} value={o[k]} maxChar={(businessobject_data.types[k] ? businessobject_data.types[k].maxChar : null)} name={k} presentation={presentationObject[k]} setValue={v => set(k,v,i)} notNull={businessobject_data.types[k]?.notnull} />  
                        }
                    })}
                    <button className="link" disabled={readOnly}  onClick={() => onRemove(i)}>entfernen</button>
                </div>
            ))}
            </div>
            {create && <button className="small" onClick={onAdd} disabled={readOnly}>{presentation} hinzufügen</button>}
        </div>
    )
}

/**
 * Generiert einen Input zur Eingabe mehrerer Werte eines Objektes in einer Zeile
 * Es ist möglich, mehrere Objekte zu erstellen
 * 
 * @param {Object} value - wert des Inputs
 * @param {boolean} create - gibt an, ob neue Elemente erstellt werden dürfen
 * @param {Function} onCreate - Funktion um eine neue Option zu erstellen, wird weiter an DropdownInput gegeben
 * @param {Object} defaultObject - Defaulteinträge des zu erstellenden Objektes
 * @param {Object} presentationObject - Label der Inputs eines Objektes
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {Function} setValue - Funktion zum Setzen des Wertes
 * @param {String} clsNameExt - String der als zusätzliche CSS-Klasse verwendet wird
 * @param {Object} optionsObject - Auswahl Optionen eines Dropdown, wenn ein Input des Objektes durch ein Dropdown dargestellt werden soll
 * @param {Object} disabledObject - gibt an, welches der Inputs gesperrt werden soll
 * @param {Object} invalidObject - gibt an, welches der Inputs als ungültig markiert werden soll
 * @returns {HTML} Input zur Eingabe mehrerer Werte eines Objektes in einer Zeile
 */
export const RowInput = ({value = [], onCreate, readOnly, setValue, setIndexValue, defaultObject, presentation, name, presentationObject, optionsObject, clsNameExt, disabledObject, invalidObject, requiredObject, highlightDruck=false}) => {
    const createNew = Array.isArray(value)
    if(!createNew){value = [value]}
    const [showTablePopUp, setShowTablePopUp] = useState(-1)
    const [sort, setSort] = useState(Object.keys(presentationObject)[2])
    const [sortASC, setSortASC] = useState(true)
    const [sortedValue, setSortedValue] = useState(value)
    const [valueChanged, setValueChanged] = useState(false)
    if(!name)(name = presentation)

    useEffect(() => {
        if (valueChanged){
            setSortedValue(sortList(sort, sortASC, value, (a, v, e) => {return optionsFilter(optionsObject[a], 'value', v).label}))//attribute, value, element
            setValueChanged(false)
        }
    }, [sort, sortASC, valueChanged])

    useEffect(() => {
        setValueChanged(true)
    }, [sort, sortASC])

    function set(k, v, i){
        i = value.indexOf(sortedValue[i])
        if (k === 'status'){
            v = (v ? data.status.GESPERRT : data.status.FREIGABE_QUALITAETSSICHERUNG)
        }
        if (createNew && !setIndexValue){
            value[i] = {...value[i], [k]:v}
            setValue(value)
        } else if (setIndexValue) {
            setIndexValue(i, {...value[i], [k]:v})
        } else {
            setValue({...value[0], [k]:v})
        }
        setValueChanged(true)
    }

    function onAdd(){
        value.push({...defaultObject})
        setValue(value)
        setValueChanged(true)

    }

    function onRemove(i){
        i = value.indexOf(sortedValue[i])
        if (createNew){
            value.splice(i, 1)
            setValue(value)
        } else {
            setValue(null)
        }
        setValueChanged(true)
    }

    function isDisabled(i, k){
        i = value.indexOf(sortedValue[i])
        if (readOnly || (businessobject_data.types[k] && businessobject_data.types[k].readOnly)){
            return true
        } 
        if (disabledObject){
            return ((disabledObject[k] && typeof disabledObject[k] !== 'object') ? disabledObject[k] : (disabledObject[i] ? disabledObject[i][k] : false))
        } else {
            return false
        }
    }
    const columCount = Object.keys(presentationObject).length+1 - (highlightDruck ? 1 : 0 )

    return (
        <div className='row-input'>
            {presentation && <div className="label">{presentation}<TooltipInfo name={name} /></div>}
            <div className='row-input-content' style={{gridTemplateColumns: 'repeat('+columCount+', auto)'}} >
                <div className='row-input-header'>
                    {Object.keys(presentationObject).map((k, i) => (
                        <div className='row-input-header-item' key={i} onClick={(e) => {setSort(k); setSortASC(!sortASC)}}>{presentationObject[k]}{sort === k && sortASC ? <i className="arrow down" /> : sort === k && !sortASC ? <i className="arrow up" /> : null}</div>
                    ))}
                    {!highlightDruck && <div className='row-input-header-item inline-button no-mobile no-tablet'></div>}
                </div>
                <div className={(clsNameExt?'row-input-body '+clsNameExt:'row-input-body')}>
                    {sortedValue.map((o, i) => (
                        <div className='row-input-body-row' key={i} >
                            {Object.keys(presentationObject).map((k, j) => {
                                let sortedIndex = value.indexOf(sortedValue[i])
                                if (businessobject_data.types[k]){
                                    if (businessobject_data.types[k].type === data.inputTypes.advanced.DROPDOWN){
                                        return <div className='row-input-body-row-item' key={i*1000+j}><DropdownInput reactSelectKey={i*1000+j*10+1} readOnly={isDisabled(i,k)} clearable={(businessobject_data.types[k] && businessobject_data.types[k].searchable)} onCreate={(businessobject_data.types[k].create?(v)=>{onCreate(sortedIndex, v); onRemove(i)}:null)} searchable={(businessobject_data.types[k] && businessobject_data.types[k].searchable)} name={k} value={o[k]} setValue={v => set(k,v,i)} options={(optionsObject[sortedIndex] && optionsObject[sortedIndex][k] ? optionsObject[sortedIndex][k]  : optionsObject[k])} multiSelect={(businessobject_data.types[k] && businessobject_data.types[k].multiSelect)} notNull={(businessobject_data.types[k] && businessobject_data.types[k].notnull)} table={false} /></div>
                                    }
                                    if (businessobject_data.types[k].type === data.inputTypes.advanced.FILE){
                                        return <div className='row-input-body-row-item' key={i*1000+j}><FileInput field={k} readOnly={isDisabled(i,k)} value={o[k]} setValue={v => set(k, v, i)} dateitypOptions={(optionsObject[sortedIndex] && optionsObject[sortedIndex][k] ? optionsObject[sortedIndex][k]  : optionsObject[k])} size='2x'/></div>
                                    }
                                    if (businessobject_data.types[k].type === data.inputTypes.standard.TEXTAREA){
                                        return <div className='row-input-body-row-item' key={i*1000+j}> <PopUpTextarea readOnly={isDisabled(i,k)} value={o[k]} name={k} title={presentationObject[k]} setValue={v => set(k,v,i)}  /> </div>
                                    }
                                    if (businessobject_data.types[k].type === data.inputTypes.advanced.OBJECTFK){
                                        return <div className='row-input-body-row-item' key={i*1000+j}> <ObjectFKInput readOnly={isDisabled(i,k)} isInvalid={(invalidObject && invalidObject[i] && invalidObject[i][k])} value={o[k]} name={k} presentation={presentationObject[k]} setValue={v => set(k,v,i)} multiSelect={businessobject_data.types[k].multiSelect} create={businessobject_data.types[k].create} businessObject={businessobject_data.types[k].businessObject} objectList={optionsObject[k]} onSelectCategory={optionsObject._onSelectCategory[k]} filter={(businessobject_data.types[k].filterBy ? {attr: businessobject_data.types[k].filterBy, value: o[businessobject_data.types[k].filterBy]} : null)} ownerId={o.id} valueProperty={businessobject_data.types[k].selectProperty} presentationProperty={(businessobject_data.types[k].presentationProperty ? businessobject_data.types[k].presentationProperty : businessobject_data.types[k].selectProperty)}  /> </div>
                                    }
                                    if (businessobject_data.types[k].type === data.inputTypes.advanced.TABLE){
                                        return <div className='row-input-body-row-item' key={i*1000+j}>
                                            {showTablePopUp === i && <PopUp title={presentationObject[k] + ' hinzufügen'} 
                                                content={<div className='popup-input-details'>
                                                    <TableInput value={o[k]} presentation={presentationObject[k]} presentationObject={optionsObject[k]._presentObj} disabledObject={disabledObject[k]} optionsObject={optionsObject[k]._optionsObj} options={optionsObject[k]._options} name={k} readOnly={isDisabled(i,k)} setValue={v => set(k,v,i)} />
                                                </div>}
                                                close={() => {setShowTablePopUp(-1)}} closeText='Speichern' />  
                                            }
                                            <button className="small" onClick={() => (setShowTablePopUp(i))} disabled={isDisabled(i,k)}>{presentationObject[k]} hinzufügen</button>
                                        </div>
                                    }
                                    else {
                                        return <div className={'row-input-body-row-item' + (highlightDruck && k === 'druck' ? ' highlight' : '')} key={i*1000+j}><InputByType type={businessobject_data.types[k].type} isInvalid={(invalidObject && invalidObject[i] && invalidObject[i][k])} asNumber={businessobject_data.types[k].asNumber} readOnly={isDisabled(i,k)} value={(k==='status' ? ((!o[k] || o[k] === data.status.FREIGABE_QUALITAETSSICHERUNG) ? false : true) : o[k])} name={k} setValue={v => set(k,v,i)} notNull={(businessobject_data.types[k] && businessobject_data.types[k].notnull)} /> </div>
                                    }
                                } else {
                                    return <div className='row-input-body-row-item' key={i*1000+j}><TextInput readOnly={isDisabled(i,k)} isInvalid={(invalidObject && invalidObject[i] && invalidObject[i][k])} value={o[k]} name={k} setValue={v => set(k,v,i)} notNull={(businessobject_data.types[k] && businessobject_data.types[k].notnull)} /></div>
                                }
                            })}
                            {/* {createNew && !highlightDruck && <button className="inline no-mobile no-tablet" disabled={isDisabled(i,'remove')} onClick={() => (onRemove(i))}>-</button>} */}
                            {createNew && !highlightDruck && <div className='row-input-body-row-item mobile-w100' key={0}><button className="micro no-mobile no-tablet" disabled={isDisabled(i,'remove')}  onClick={() => onRemove(i)}>-</button><button className="link mobile tablet" disabled={isDisabled(i,'remove')}  onClick={() => onRemove(i)}>entfernen</button></div>}
                        </div>
                    ))}
                </div>
            </div>
            {(createNew && !highlightDruck ? <button className="small" disabled={isDisabled(0,'add')} onClick={onAdd}>hinzufügen</button> : null)}
        </div>
    )
}

/**
 * Generiert einen Input zur Auswahl eines Objektes und dessen Key als Fremdschlüssel
 * Im PopUp wird die Suche zur Auswahl aufgerufen
 * 
 * @param {Object} value - wert des Inputs
 * @param {boolean} create - gibt an, ob neue Elemente erstellt werden dürfen
 * @param {boolean} multiSelect - gibt an, ob mehrere Elemente ausgewählt werden können
 * @param {Object} presentation- Label des Inputs
 * @param {Object} presentationProperty - Key des Wertes des Objektes das als Fremdschlüssel angezeigt werden soll
 * @param {Object} valueProperty - Wert des Objektes das als Fremdschlüssel verwendet werden soll
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {Function} setValue - Funktion zum Setzen des Wertes
 * @param {int} ownerId - ID des eigenen Objektes, wird aus der Auswahlliste entfernt
 * @param {String} onSelectCategory - Kategoriefilter, wird an Suche weitergegeben
 * @param {String} businessObject - Geschäftsobjekt dass ausgewählt werden soll
 * @param {Object} objectList - Liste aller zur Auswahl stehenden Elemente
 * @param {Object} filter - Filter Value und Attribut zum Filtern der Liste aller zur Auswahl stehenden Elemente
 * @param {boolean} isInvalid - gibt an, ob das Element als ungültig markiert werden soll
 * @returns {HTML} Input zur Auswahl eines Fremdschlüssels
 */
export const ObjectFKInput = ({presentation, name, objectList, businessObject, readOnly, setValue, value, create, ownerId, multiSelect, valueProperty, onSelectCategory, presentationProperty, filter, isInvalid, setOverwrite, notNull}) => {
    const [showPopUp, setShowPopUp] = useState(false)
    const [showCreateInPopUp, setShowCreateInPopUp] = useState(false)
    const [showEditInPopUp, setShowEditInPopUp] = useState(false)
    const [objectIndex4Update, setObjectIndex4Update] = useState(-1)
    if(!name)(name = presentation)
    if (typeof value !== 'object'){
        value = [value]
    }
    if ((!objectList || objectList.length === 0) && !valueProperty){
        objectList = value
    }
    if (filter){
        objectList = optionsFilter(objectList, filter.attr, filter.value);
    }
    if (ownerId){
        objectList = optionsFilter(objectList, 'id', ownerId, true)
        if (businessObject === data.businessObject.competence){
            objectList = optionsFilter(objectList, 'parentId', ownerId, true)
        }
    }
    function onCreate(){
        setShowCreateInPopUp(true)
    }

    function onSelect(id, index){
        setShowPopUp(false)
        if (!create || (!showCreateInPopUp && businessObject === data.businessObject.user)){
            setShowEditInPopUp(false)
            if (multiSelect){
                value.push(id)
                setValue(value)
            } else {
                setValue(id)
            }
        } else {
            if (showCreateInPopUp){
                setShowCreateInPopUp(false)
                if (multiSelect){
                    value.push(id)
                    setValue(value)
                } else {
                    if (create && valueProperty && businessObject === data.businessObject.user){
                        setValue(id[valueProperty])
                    } else {
                        setValue(id)
                    }
                }
            } else if (showEditInPopUp) {
                setShowEditInPopUp(false)
                if (multiSelect){
                    value[index] = id
                    setValue(value)
                } else {
                    setValue(id)
                }
            } else {
                setObjectIndex4Update(id)
                setShowPopUp(true)
                setShowEditInPopUp(true)
            }
        }
        if (setOverwrite){
            let obj = (objectList ? objectList.find(elem => elem[valueProperty] === id) : null)
            if (!obj && create && businessObject === data.businessObject.user) {obj = id; id = id[valueProperty]}
            if (obj){
                setOverwrite(id, obj)
            }
        }
    }

    function onRemove(i){
        if (multiSelect){
            value.splice(i, 1)
            setValue(value)
        } else {
            setValue("")
        }
    }
    
    function objectPresentation(v,i){
        let obj = (objectList ? objectList.find(elem => elem[valueProperty] === v) : null)
        if (obj){
            if (presentationProperty.includes(",")){
                let attrs = presentationProperty.split(", ")
                return (obj[attrs[0]] + ", " + (obj[attrs[1]] ? obj[attrs[1]] : ""))
            }else{
                return(obj[presentationProperty])
            }
        } else {
            if (typeof value[i] === 'string' && !!value[i]){
                return(value[i])
            } else {
                return ""
            }
        }
    }
    return (
        <div className={'object-fk-input'+((isInvalid&& (typeof isInvalid!=='object')) ? ' invalid' : '')+(notNull ? ' required' : '')} >
            {(showPopUp ? <PopUp title={presentation + ' suchen'}
                    content={<div className='popup-input-details'>
                        {( (!showCreateInPopUp && !showEditInPopUp) ? (businessObject === data.businessObject.user ? 
                                <Administration_Benutzer onSelect={(o) => onSelect(o)} userList={((valueProperty && value) ? optionsFilter(objectList, valueProperty, value, true) : objectList)} onSelectProperty={valueProperty} onSelectCreate={(create ? onCreate : false )} /> 
                            : 
                                <Suche businessObject={businessObject} objectList={((valueProperty && value) ? optionsFilter(objectList, valueProperty, value, !create) : objectList)} onSelectCategory={onSelectCategory} onSelect={(o) => onSelect(o)} onSelectProperty={(valueProperty ? valueProperty : 'INDEX')} onSelectCreate={(create ? onCreate : false )} />
                            ) 
                        :   (showCreateInPopUp ? (businessObject === data.businessObject.user ? 
                                    <Administration_SpecificBenutzer onSelect={(o) => onSelect(o)} /> :
                                <Pflegen businessObject={businessObject} onSelect={(o) => onSelect(o)} onSelectProperty={valueProperty} />)
                            :
                                <Pflegen businessObject={businessObject} onSelect={(o) => onSelect(o, objectIndex4Update)} onSelectObject={value[objectIndex4Update]} onSelectRemove={() => onRemove(objectIndex4Update)} onSelectProperty={valueProperty} />
                            )
                        )}
                    </div>} 
                abort={() => {setShowPopUp(false); setShowCreateInPopUp(false); setShowEditInPopUp(false);}} />  
            : null)}
            <div className="label" id="presentation">{presentation}{notNull ? <span className="required-label">*</span> : null}<TooltipInfo name={name} /></div>
            {presentationProperty ? <div className='object-fk-input-values'>
                {value.map((v, i) => (
                    <div className="input-w-button" key={i}><input type='text' disabled value={objectPresentation(v,i)} /><button className="inline" disabled={readOnly} onClick={() => {onRemove(i)}}>-</button></div>
                ))}
            </div> : null}
            <button className="small" onClick={() => (setShowPopUp(true))} disabled={readOnly}>{presentation} {(create? 'verwalten' : 'suchen')}</button>
        </div>
    )
}

/**
 * Generiert ein DropdownInput dessen ausgewählten Werte in einer Tabelle gesammelt werden
 * Zu den Ausgewählten Objekten können in der Tabelle weitere Attribute bearbeitet werden
 * 
 * @param {Object} value - wert des Inputs
 * @param {Function} onCreate - Funktion um eine neue Option zu erstellen, wird weiter an DropdownInput gegeben
 * @param {Object} presentation- Label des Inputs
 * @param {Object} presentationObject - Label der Inputs eines Objektes
 * @param {String} name - interner Name des Inputs
 * @param {Object} options - Auswahl Optionen des Dropdown
 * @param {Object} disabledObject - gibt an, welches der Inputs gesperrt werden soll
 * @param {Object} optionsObject - Auswahl Optionen eines Dropdown, wenn ein Input des Objektes durch ein Dropdown dargestellt werden soll
 * @param {boolean} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {Function} setValue - Funktion zum Setzen des Wertes
 * @returns {HTML} Input zur Auswahl eines Fremdschlüssels
 */
export const TableInput = ({value = [], onCreate, presentation, presentationObject, disabledObject, name, readOnly, setValue, setIndexValue, options, optionsObject, valueProperty, highlightDruck=false}) => {
    function set(k, v, i){
        if (setIndexValue){
            setIndexValue(i, {...value[i], [k]:v})
        } else {
            value[i] = {...value[i], [k]:v}
            setValue(value)
        }
    }

    function onAdd(v){
        value.push(v)
        setValue(value)
    }

    function onRemove(i){
        value.splice(i, 1)
        setValue(value)
    }
    function isDisabled(i, k){
        if (readOnly || (businessobject_data.types[k] && businessobject_data.types[k].readOnly)){
            return true
        } 
        if (disabledObject){
            return ((disabledObject[k] && typeof disabledObject[k] !== 'object') ? disabledObject[k] : (disabledObject[i] ? disabledObject[i][k] : false))
        } else {
            return false
        }
    }

    options = options?.filter(o => (valueProperty && o.value && o.value[valueProperty] && !objectList2AttrList(value, valueProperty).includes(o.value[valueProperty])))

    const columCount = Object.keys(presentationObject).length+1
    return (
        <div className='table-input' >
            {(highlightDruck ? <div className="label">{presentation}</div> : <DropdownInput presentation={presentation} name={name} readOnly={readOnly} searchable setValue={onAdd} options={options} onCreate={onCreate} />)}
            <div className='table' style={{gridTemplateColumns: 'repeat('+columCount+', auto)'}}>
                <div className='table-header'>
                    {Object.keys(presentationObject).map((k, i) => (
                        <div className='table-header-item' key={i} >{presentationObject[k]}</div>
                    ))}
                    <div className='table-header-item placeholder'></div>
                </div>
                <div className='table-body'>
                    {value.map((o,i) => (
                        <div className='table-body-row' key={i}>
                            {Object.keys(presentationObject).map((k, j) => {
                                if (businessobject_data.types[k]){
                                    if (businessobject_data.types[k].type === data.inputTypes.advanced.DROPDOWN){
                                        return <div className='table-body-row-item' key={j}><DropdownInput reactSelectKey={j*10000+1} readOnly={isDisabled(i,k)} clearable={false} name={k} value={o[k]} setValue={v => set(k,v,i)} options={optionsObject[k]} /> </div>
                                    } else {
                                        return <div className={'table-body-row-item' + (highlightDruck && k === 'druck' ? ' highlight' : '')} key={j}><InputByType type={businessobject_data.types[k].type} asNumber={businessobject_data.types[k].asNumber} readOnly={isDisabled(i,k)} value={o[k]} name={k} setValue={v => set(k,v,i)} /> </div>
                                    }
                                } else {
                                    return <div className='table-body-row-item' key={j}><TextInput readOnly={true} value={o[k]} name={k} setValue={v => set(k,v,i)} />  </div>
                                }
                            })}
                            <div className='table-body-row-item'><button className="micro" disabled={isDisabled(i,'remove')} onClick={() => (onRemove(i))}>-</button></div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export const HandleMainInputs = ({fields, tab, businessObject, returnInput = () => {}}) => {

    let tabIndex = ((tab && businessobject_data[businessObject].fields[tab])  ? Object.keys(businessobject_data[businessObject].fields).indexOf(tab) : 0 )

    return (
        <div className="main-inputs">
            {Object.keys(fields).map((field, index) => {
                if (typeof fields[field] === 'object') {
                    if (field.includes('topic')) {
                        return (
                            <div className="input-topic" key={'t'+index}>
                                {Object.keys(fields[field]).map((subfield, j) => {
                                    if (subfield.includes('group')) {
                                        return (
                                            <div className="input-group" key={'g'+index*10+j}>
                                                {Object.keys(fields[field][subfield]).map((subsubfield, j) => returnInput(businessobject_data.types[subsubfield], ((tabIndex + 1) * 100 + index*10 + 1) * 100 + j + 1, subsubfield, fields[field][subfield][subsubfield]))}
                                            </div>
                                        )
                                    }
                                    return (
                                        returnInput(businessobject_data.types[subfield], ((tabIndex + 1) * 100 + index + 1) * 100 + j + 1, subfield, fields[field][subfield])
                                    )
                                })}
                            </div>
                        )

                    }
                    if (field.includes('group')) {
                        return (
                            <div className="input-group" key={'g'+index}>
                                {Object.keys(fields[field]).map((subfield, j) => returnInput(businessobject_data.types[subfield], ((tabIndex + 1) * 100 + index + 1) * 100 + j + 1, subfield, fields[field][subfield]))}
                            </div>
                        )
                    }
                } else {
                    return returnInput(businessobject_data.types[field], (tabIndex + 1) * 100 + index + 1, field, fields[field])
                }
            })}
        </div>
    )
}