import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {useHistory, useParams} from 'react-router'

import businessobject_data from '../../../data/businessobject_data'
import data from '../../../data/_data'

import {PermissionForbidden} from '../../_helpers/ReadPermission'
import PopUp from '../../_helpers/PopUp'
import {sendGetConsultantRequest, sendCreateConsultantRequest, sendDeleteConsultantRequest, sendUpdateConsultantRequest, sendDeleteFileRequest as sendDeleteConsultantFileRequest, sendCreateFileRequest, sendFileUpload, sendFileDownload} from '../../../redux/consultants'
import {sendCreatePMCompetenceRequest, sendCreatePMQualificationRequest} from '../../../redux/competences'
import PflegenFusszeile from '../../Fusszeile'
import PflegenKopfzeile_Berater from './PflegenKopfzeile_Berater'
import {DropdownInput, TextInput, InputByType, FileInput, InLineInput, TableInput, RowInput, ObjectFKInput, HandleMainInputs} from '../../_helpers/InnerContent';
import message_data from './../../../data/message_data'
import {list2options, object2options, objectList2options, optionsFilter, defaultEncoder, equals, flattenObject2Array, optionsDataByTypeInit, getOptions, somethingChanges, getOptionsInit, objectList2AttrList, validateValues, checkInvalidation, checkReadOnly, arrayDefaultFilter, fieldData2options, enum2label, completeCompetenceAfterUpdate, filterTabValues} from './../../_helpers/functions'
import {setSuccess} from './../../../redux/businessobjects'

/**
 * Unter-Komponente für die Seiten "Beraterprofil Pflegen".
 * Bearbeitet oder legt den gewählte Berater an
 *
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {String} username - benutzername des angemeldeten Benutzers
 * @param {String} readOnly - gibt an, ob das Element schreibgeschützt ist
 * @param {String} accessStatus - status des Pflegens des Geschaeftsobjektes (suche, neu, gefuehrt)
 * @param {Object} id - die ID des konkreten Beraters
 * @param {Object} createdId - die ID des gerade erstellten Beraters
 * @param {Object} list - Liste aller Berater aus dem State, von der API
 * @param {Object} userList - Liste aller Benutzer aus dem State, von der API
 * @param {Function} get - Funktion zum request des spezifischen Beraters von der API
 * @param {Function} sendCreate - Funktion zum API call zum Erstellen des Objektes
 * @param {Function} sendUpdate - Funktion zum API call zum Aktualisieren des Objektes
 * @param {Function} sendDelete - Funktion zum API call zum Löschen des Objektes
 * @param {Function} sendCompetenceCreateRequest - Funktion zum API call zum Erstellen einer Anfrage für eine Kompetenz
 * @param {Function} sendQualificationCreateRequest - Funktion zum API call zum Erstellen einer Anfrage für eine Qualifikation
 * @param {Function} sendCreateFile - Funktion zum API call zum Erstellen einer Datei
 * @param {Function} sendUploadProfilePic - Funktion zum API call zum Hochladen eines Profilbildes
 * @param {Function} sendDeleteFile - Funktion zum API call zum zum Löschen einer Datei
 * @param {Function} sendFileDownload - Funktion zum API call zum Downloaden einer Datei
 * @param {Object} requestedFile4Download - Die Angefragte Datei
 * @param {Function} sendProfilPicDownload - Funktion zum API call zum Download des Profilbildes
 * @param {String} errorMsg - errorMsg wenn API call fehlschlägt
 * @param {String} fieldData - Feldauspraegungen aus dem State
 * @param {String} competenceData - Kompetenzen zur Auswahl im Dropdown o.ä.
 * @param {String} fieldDataError - errorMsg wenn API call, speziell für die Feldauspraegungen fehlschlägt
 * @param {String} onSelect - Funktion welche aufgerufen wird wenn Angeleggt wird, nur wnen Komponentnet direkt und nicht über das Routing aufgerufen wird
 * @param {String} onSelectProperty - Key des als Fremdschlüssel zu verwendenen Objektes bei onSelect
 * @param {String} successMsg - successMsg wenn Anlegen oder Update erfolgreich
 * @param {String} pmQualificationSuccess - successMsg für die Anfrage zum Erstellen einer Qualifikation
 * @param {String} pmCompetenceSuccess - successMsg für die Anfrage zum Erstellen einer Kompetenz
 * @param {String} setfurtherSuccess - successMsg wenn auch das Update oder Anlegen von Dateien erfolgreich
 * @returns {HTML} Seite zum Pflegen der Geschaeftsobjekte
 * @see PflegenKopfzeile
 * @see PflegenFusszeile
 */
function Pflegen_Berater({role, username, createdId, readOnly, accessStatus, id, object, list, userList, get, sendCreate, sendUpdate, sendDelete, sendCompetenceCreateRequest, sendQualificationCreateRequest, sendCreateFile, sendUploadProfilePic, sendDeleteFile, sendFileDownload, requestedFile4Download, sendProfilPicDownload, errorMsg, fieldData, competenceData, fieldDataError, onSelect, onSelectProperty, successMsg, setfurtherSuccess, pmQualificationSuccess, pmCompetenceSuccess}) {
    // some State Var for PopUp and Notification about the success of an api call
    const [showDeletePopUp, setShowDeletePopUp] = useState(false)
    const [showLockPopUp, setShowLockPopUp] = useState(false)
    const [failedValidation, setFailedValidation] = useState({notnull: {}, regex: {}, semantic: {}})
    const [tabOfFailure, setTabOfFailure] = useState('')
    const [specificObject, setSpecificObject] = useState(object)
    const [wantedStatus, setWantedStatus] = useState(null)

    // param in the URl for the correct geschaeftsobjekt
    // var { geschaeftsobjekt, id } = useParams()
    id = id.toUpperCase()
    const businessObject = data.businessObject.consultant

    // tabs
    const [tab, setTabintern] = useState(Object.keys(businessobject_data.berater.fields)[0])

    const setTab = (tab) => {
        if (somethingChanges(accessStatus, values, specificObject, businessObject) && !validateValues(setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure)) {
            if (tabOfFailure) {
                setTabintern(tabOfFailure)
                return
            } else {
                return
            }
        }
        window.scrollTo({top: 0, behavior: 'smooth'})
        setTabintern(tab)
    }

    useEffect(()=> {if (tabOfFailure) {window.scrollTo({ top: 0, behavior: 'smooth' }); setTabintern(tabOfFailure)}}, [tabOfFailure])

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [])

    const history = useHistory()
    useEffect(() => {
        if (successMsg === message_data.success.redux.default) {
            history.push('/pflegen')
        }
    }, [successMsg])
    // request api Data if needed
    useEffect(() => {
        if (accessStatus !== data.accessStatus.create) {
            get(id)
        }
    }, [id])
    // if(accessStatus === data.accessStatus.create){object = businessobject_data.berater.default};
    const getValues = () => {
        if (object && accessStatus === data.accessStatus.update) {
            // look for the object in other status
            if (wantedStatus && wantedStatus != object?.status && !!parseInt(id) && !Array.isArray(object)){
                history.push('/pflegen/berater/'+object?.kuerzel)
            }
            if (Array.isArray(object)) {
                let tempObject = null
                if (role === data.roles.MITARBEITER || object[0].kuerzel == username) {
                    tempObject = object.find((b) => (b.status === data.status.BEARBEITUNG || b.status === data.status.FREIGABE_MITARBEITER) && b.kuerzel == username)
                }
                if (!tempObject) {
                    tempObject = object.find((b) => b.status === data.status.FREIGABE_QUALITAETSSICHERUNG)
                }
                if (wantedStatus){
                    let wantedObject = object.find((o) => o.status === wantedStatus)
                    if (wantedObject){
                        if (wantedObject.status !== tempObject.status){
                            readOnly = true
                        }
                        return wantedObject
                    }
                }
                return tempObject
            }
            return object
        } else if (accessStatus === data.accessStatus.create) {
            return defaultEncoder(businessobject_data.berater.default)
        } else {
            return {}
        }
    }

    const [error, setError] = useState('')
    // values for the object to safe
    const [values, setValues] = useState(getValues())

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }, [error, errorMsg])

    if (values) {
        readOnly = readOnly ? readOnly : PermissionForbidden(role, username, id, data.businessObject.consultant, values)
    }
    //else if (id === 'neu' && (geschaeftsobjekt === 'kompetenz' || geschaeftsobjekt === 'qualifikation')){object = {[geschaeftsobjekt]: []};}
    //else if (businessobjectsList && geschaeftsobjekt == 'kompetenz'){object = {kompetenz: []}; object.kompetenz.push(businessobjectsList.kompetenz[id][id2])}
    //else if (businessobjectsList && geschaeftsobjekt == 'qualifikation'){object = {qualifikation: []}; object.qualifikation.push(businessobjectsList.kompetenz.qualifikation[id][id2])}

    // load object
    useEffect(() => {
        const tempValues = getValues()
        setValues(defaultEncoder(tempValues))
        setSpecificObject(tempValues)
    }, [object, id, wantedStatus])
    // load profile pic
    useEffect(() => {
        if (accessStatus === data.accessStatus.update && id && specificObject && specificObject.profilbild && specificObject.profilbild.id && !specificObject.profilbild.dateiData) {
            sendProfilPicDownload(specificObject.id, specificObject.profilbild.id)
        } // download the profile pic
    }, [specificObject])

    // open PopUpto to Confirm on Function call
    const onDelete = () => {
        setShowDeletePopUp(true)
    }
    const onLock = () => {
        setShowLockPopUp(true)
    }
    // close PopUp and do api call
    const onDelete_sure = () => {
        setShowDeletePopUp(false)
        sendDelete(id)
    }
    const onLock_sure = () => {
        setShowLockPopUp(false)
        values.status = (specificObject.status === data.status.GESPERRT ? data.status.FREIGABE_QUALITAETSSICHERUNG : data.status.GESPERRT)
        onSave()
    }
    // set status for Release according to userrole
    const onRelease = () => {
        values.status = (role === data.roles.MITARBEITER ? data.status.FREIGABE_MITARBEITER : data.status.FREIGABE_QUALITAETSSICHERUNG)
        onSave()
    }

    const onSavePM = () => {
        values.status = data.status.FREIGABE_MITARBEITER
        onSave()
    }

    const onSavePMPrivate = () => {
        values.status = data.status.BEARBEITUNG
        onSave()
    }

    // validate and copy the object to save it via API call
    const onSave = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure)) {
            if (tabOfFailure) {
                setTabintern(tabOfFailure)
                return
            } else {
                return
            }
        }
        if (values.status === data.status.FREIGABE_QUALITAETSSICHERUNG && values.kompetenzen){
            for (let k of values.kompetenzen){
                if (k.status !== data.status.FREIGABE_QUALITAETSSICHERUNG){
                    setError(message_data.error.pflegen.notreleased)
                    return;
                }
            }
        }
        let tempObject = getValues()
        let files2delete = []
        if (tempObject.dateien) {
            for (let file of tempObject.dateien) {
                if (!values.dateien?.find((f) => f.id === file.id)) {
                    files2delete.push(file.id)
                }
            }
        }
        if (tempObject.profilbild && equals(values.profilbild, businessobject_data.datei.default)) {
            files2delete.push(tempObject.profilbild.id)
        }

        for (let key of Object.keys(values)) {
            if (businessobject_data.types[key] && businessobject_data.types[key].unique) {
                if (!checkUnique(key, tempObject)) {
                    return
                }
            }

            if (!!values[key] || (!!tempObject[key] && !values[key])) {
                if (Array.isArray(values[key])) {
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                    if (tempValues.length > 0 || (tempObject[key] && tempObject[key].length > 0)) {
                        tempObject[key] = tempValues
                    }
                } else {
                    tempObject[key] = values[key]
                }
            }
        }
        sendUpdate(tempObject)

        // if (object.profilbild && !object.profilbild.id && object.profilbild.datei){
        //     sendUploadProfilePic(object.id, object.profilbild.datei)
        // }
        // if (object.dateien){
        //     for (let file of object.dateien){
        //         if(!equals(file,businessobject_data.datei.default) && !file.id && file.datei){
        //             sendCreateFile(object.id, file)
        //         }
        //     }
        // }
        for (let id of files2delete) {
            sendDeleteFile(tempObject.id, id)
        }
    }

    // lock the Object and create it
    const onCreateLock = () => {
        values.status = data.status.GESPERRT
        onCreate()
    }

    // validate and create the object via API call
    const onCreate = () => {
        if (!validateValues(setError, values, failedValidation, setFailedValidation, businessObject, setTabOfFailure)) {
            if (tabOfFailure) {
                setTabintern(tabOfFailure)
                return
            } else {
                return
            }
        }
        if (values.status === data.status.FREIGABE_QUALITAETSSICHERUNG && values.kompetenzen){
            for (let k of values.kompetenzen){
                if (k.status !== data.status.FREIGABE_QUALITAETSSICHERUNG){
                    setError(message_data.error.pflegen.notreleased)
                    return;
                }
            }
        }
        object = {}
        for (let key of Object.keys(values)) {
            if (businessobject_data.types[key] && businessobject_data.types[key].unique) {
                if (!checkUnique(key)) {
                    return
                }
            }
            if (key !== 'dateien' && ((!!values[key] && !equals(values[key], businessobject_data.berater.default[key])) || (businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject)))))) {
                if (Array.isArray(values[key])) {
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(values[key], key, businessObject)
                    if (tempValues.length > 0) {
                        object[key] = tempValues
                    }
                } else {
                    object[key] = values[key]
                }
            }
        }
        sendCreate(object)

        if (onSelect) {
            if (onSelectProperty) {
                onSelect(object[onSelectProperty])
            } else {
                onSelect(object)
            }
        }
    }

    // after creating or updating the object, save files and images via API call
    useEffect(() => {
        if (createdId) {
            let furtherUploads = false
            if (values.profilbild && !equals(values.profilbild, businessobject_data.berater.default.profilbild) && !values.profilbild.id && values.profilbild.dateiData) {
                furtherUploads = true
                sendUploadProfilePic(createdId, values.profilbild.dateiData)
            }
            if (!equals(values.dateien, businessobject_data.berater.default.dateien) && values.dateien) {
                for (let file of values.dateien) {
                    if (!equals(file, businessobject_data.dateiData.default) && !file.id && file.dateiData) {
                        furtherUploads = true
                        sendCreateFile(createdId, file)
                    }
                }
            }

            if (!furtherUploads) {
                setfurtherSuccess()
            }
        }
    }, [createdId])

    // download a file
    const onFileDownload = (fileId) => {
        if (accessStatus === data.accessStatus.update && fileId) {
            sendFileDownload(specificObject.id, fileId)
        }
    }

    // send request to create a competence
    const onCompetenceCreateReq = (competence) => {
        sendCompetenceCreateRequest({...competence, status: data.status.FREIGABE_MITARBEITER, druck: false})
    }

    // send request to create a qualification
    const onQualificationCreateReq = (qualification) => {
        const tempQ = {}
        for (let qDefaultKey of Object.keys(businessobject_data.qualifikation.default)) {
            if (!['druck', 'status', 'kompetenzenId'].includes(qDefaultKey) && !equals(qualification[qDefaultKey], businessobject_data.qualifikation.default[qDefaultKey])) {
                tempQ[qDefaultKey] = qualification[qDefaultKey]
            }
        }
        sendQualificationCreateRequest({...tempQ, status: data.status.FREIGABE_MITARBEITER, druck: false})
    }

    // set the competence or qualification to the object, if created successfully
    useEffect(() => {
        if (values && pmQualificationSuccess) {
            setValues({...values, qualifikationen: [...values['qualifikationen'], pmQualificationSuccess]})
        }
    }, [pmQualificationSuccess])
    useEffect(() => {
        if (values && pmCompetenceSuccess) {
            setValues({...values, kompetenzen: [...values['kompetenzen'], pmCompetenceSuccess]})
        }
    }, [pmCompetenceSuccess])

    // check if the User is unique
    const checkUnique = (prop, obj = object) => {
        if (!values[prop]) {
            setError(message_data.error.pflegen.unsetUser)
            return false
        }
        if (list) {
            for (let b of list) {
                if (b[prop] === values[prop] && values[prop] !== obj[prop]) {
                    setError(message_data.error.pflegen.unsetUser)
                    return false
                }
            }
        }
        return true
    }

    // load functions to get keys and options data
    const optionsDataByType = optionsDataByTypeInit(competenceData, fieldData2options(fieldData))
    const getOptions = getOptionsInit(optionsDataByType)

    var tabs
    var fields
    tabs = Object.keys(businessobject_data.berater.fields)
    fields = businessobject_data.berater.fields[tab]

    // return inputfield for each key by type
    //load options for the input if needed
    function returnInput(type, index, key, presentation) {
        if (type) {
            switch (type.type) {
                case data.inputTypes.advanced.DROPDOWN:
                    let optionsDrop = optionsDataByType(key)
                    return <DropdownInput key={index} multiSelect={!!type.multiSelect} value={values[key]} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} setValue={(v) => setValues({...values, [key]: v})} options={optionsDrop} searchable={type.searchable ? true : false} notNull={(typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject))} />
                case data.inputTypes.advanced.FILE:
                    return <FileInput key={index} picture={!!type.picture} requestedFile={requestedFile4Download} downloadFile={onFileDownload} multiple={!!type.multiSelect} dateitypOptions={optionsDataByType('dateityp')} presentation={presentation} field={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} value={values[key]} setValue={(v) => setValues({...values, [key]: v})} />
                case data.inputTypes.advanced.INLINEDEFINE:
                    let optionsObjInline = getOptions(key)
                    return (
                        <InLineInput
                            key={index}
                            name={key}
                            presentation={presentation}
                            create={!!type.create}
                            readOnly={checkReadOnly(key, readOnly, accessStatus)}
                            setValue={(v) => setValues({...values, [key]: v})}
                            value={values[key]}
                            defaultObject={{...businessobject_data[type.dataAttribute ? type.dataAttribute : key].default}}
                            presentationObject={businessobject_data[type.dataAttribute ? type.dataAttribute : key].fields}
                            optionsObject={optionsObjInline}
                            invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, values[key])}
                        />
                    )
                case data.inputTypes.advanced.TABLE:
                    let optionsObjTable = getOptions(key)
                    let dropdownOptions = optionsDataByType(key)
                    if (key === 'kompetenzen') {
                        completeCompetenceAfterUpdate((v) => setValues({...values, [key]: v}), values[key], competenceData, type.dataAttribute, ['titel', 'kategorie', 'status'], type.idProperty)
                        return (
                            <div key={index} className="input-group-horizontal">
                                {['TECHNIK', 'THEMA'].map((k, i) => {
                                    let tempDisabled = {kategorie: true, titel: true}
                                    let presentationDisabled = {druck: true, titel: true, niveau: true}
                                    let filteredValues = ((values && values[key]) ? values[key].filter(c => c.kategorie === k) : [])
                                    for (let i in filteredValues){
                                        if (filteredValues[i].status !== data.status.FREIGABE_QUALITAETSSICHERUNG){
                                            tempDisabled = {...tempDisabled, [i]: presentationDisabled}
                                        }
                                    }
                                    return <TableInput
                                        key={index * 100 + i}
                                        presentation={enum2label(k)}
                                        name={key + k}
                                        readOnly={checkReadOnly(key, readOnly, accessStatus)}
                                        disabledObject={tempDisabled}
                                        onCreate={(role === data.roles.MITARBEITER ? (v) => (onCompetenceCreateReq({kategorie: k, titel: v})): null)}
                                        presentationObject={businessobject_data.kompetenz.zoFields}
                                        value={filteredValues}
                                        options={optionsDataByType(key, k)}
                                        optionsObject={optionsObjTable}
                                        setValue={(l) => {
                                            let c = values[key] ? values[key].filter((c) => c.kategorie !== k) : []
                                            c.push(...l)
                                            setValues({...values, [key]: c})
                                        }}
                                        valueProperty={type.idProperty ? type.idProperty : key}
                                    />
                                })}
                            </div>
                        )
                    } else {
                        completeCompetenceAfterUpdate((v) => setValues({...values, [key]: v}), values[key], competenceData, type.dataAttribute, ['titel', 'name'], type.idProperty)
                        return <TableInput key={index} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, accessStatus)} presentationObject={businessobject_data.sprache.zoFields} disabledObject={{titel: true}} value={values[key]} setValue={(v) => setValues({...values, [key]: v})} options={dropdownOptions} optionsObject={optionsObjTable} valueProperty={type.idProperty ? type.idProperty : key} />
                    }
                case data.inputTypes.advanced.ROWDEFINE:
                    let optionsObjRow = getOptions(key)
                    let disabledObj = {}
                    for (let qId in values[key]) {
                        if (key === 'qualifikationen') {
                            if (!values[key][qId].typ) {
                                disabledObj[qId] = {...disabledObj[qId], qualifikationTitel: true}
                            }
                            if (values[key][qId].qualifikationId) {
                                for (let qK of Object.keys(businessobject_data.qualifikation.default)) {
                                    disabledObj[qId] = {...disabledObj[qId], [qK]: true}
                                }
                            }
                            optionsObjRow = {...optionsObjRow, [qId]: {qualifikationTitel: optionsDataByType('qualifikationTitel', values[key][qId][businessobject_data.types.qualifikationTitel.filterBy])}}
                            if (values[key][qId].status === data.status.FREIGABE_MITARBEITER) {
                                optionsObjRow = {
                                    ...optionsObjRow,
                                    [qId]: {
                                        ...optionsObjRow[qId],
                                        qualifikationTitel: [
                                            {
                                                value: values[key][qId].qualifikationId,
                                                label: (competenceData && competenceData['qualifikation'] && optionsFilter(competenceData['qualifikation'][values[key][qId][businessobject_data.types.qualifikationTitel.filterBy]], 'id', values[key][qId].qualifikationId))[0] ? optionsFilter(competenceData['qualifikation'][values[key][qId][businessobject_data.types.qualifikationTitel.filterBy]], 'id', values[key][qId].qualifikationId)[0].qualifikationTitel : values[key][qId].qualifikationId,
                                            },
                                        ],
                                    },
                                }
                            }
                        }
                        if (key === 'bildungswege') {
                            if (!values[key][qId].abschlussId) {
                                disabledObj[qId] = {...disabledObj[qId], bildungstitelId: true, enddatum: true, institutId: true, arbeit: true, ort: true}
                            }
                            for (let k of Object.keys(values[key][qId])) {
                                if (businessobject_data.types[k] && businessobject_data.types[k].filterBy) {
                                    optionsObjRow = {...optionsObjRow, [qId]: {[k]: optionsDataByType(k, values[key][qId][businessobject_data.types[k].filterBy], businessobject_data.types[k].filterBy)}}
                                }
                            }
                        }
                    }
                    if (key === 'qualifikationen') {
                        completeCompetenceAfterUpdate((v) => setValues({...values, [key]: v}), values[key], competenceData, type.dataAttribute, ['qualifikationId'], 'qualifikationTitel', true, true)
                        completeCompetenceAfterUpdate((v) => setValues({...values, [key]: v}), values[key], competenceData, type.dataAttribute, ['qualifikationTitel'], 'qualifikationId', true)
                        return (
                            <RowInput
                                key={index}
                                name={key}
                                onCreate={(i, v) => {
                                    onQualificationCreateReq({...values[key][i], titel: v})
                                }}
                                clsNameExt="long"
                                readOnly={checkReadOnly(key, readOnly, accessStatus)}
                                presentationObject={businessobject_data[type.dataAttribute ? type.dataAttribute : key].zoFields}
                                presentation={presentation}
                                defaultObject={{...businessobject_data[type.dataAttribute ? type.dataAttribute : key].default, ...businessobject_data[type.dataAttribute ? type.dataAttribute : key].zoDefault}}
                                optionsObject={optionsObjRow}
                                value={values[key]}
                                setValue={(v) => setValues({...values, [key]: v})}
                                disabledObject={disabledObj}
                                invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, values[key])}
                            />
                        )
                    } else {
                        completeCompetenceAfterUpdate((v) => setValues({...values, [key]: v}), values[key], competenceData, 'bildungstitel', ['abschlussId'], 'bildungstitelId')
                        return (
                            <RowInput
                                key={index}
                                name={key}
                                readOnly={checkReadOnly(key, readOnly, accessStatus)}
                                presentationObject={businessobject_data[type.dataAttribute ? type.dataAttribute : key].fields}
                                presentation={presentation}
                                defaultObject={businessobject_data[type.dataAttribute ? type.dataAttribute : key].default}
                                optionsObject={optionsObjRow}
                                value={values[key]}
                                setValue={(v) => setValues({...values, [key]: v})}
                                disabledObject={disabledObj}
                                invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, values[key])}
                            />
                        )
                    }
                case data.inputTypes.advanced.OBJECTFK:
                    // {/* {type.businessObject === data.businessObject.user && !values[key] && <div className="info">{message_data.info.pflegen.selectUserFirst}</div>} */}
                    return (
                            <ObjectFKInput
                                key={index}
                                name={key}
                                readOnly={checkReadOnly(key, readOnly, accessStatus)}
                                presentation={presentation}
                                value={values[key]}
                                setValue={(v) => setValues({...values, [key]: v})}
                                setOverwrite={
                                    type.overwrite
                                        ? (id, o) => {
                                              let tempValues = {...values}
                                              type.overwrite.map((k, i) => {
                                                  tempValues[k] = o[k]
                                              })
                                              tempValues[key] = id
                                              setValues(tempValues)
                                          }
                                        : null
                                }
                                multiSelect={type.multiSelect}
                                create={((role === data.roles.BECKOFFICE && key === 'kuerzel') ? true : type.create)}
                                objectList={type.businessObject === data.businessObject.user ? (userList ? optionsFilter(optionsFilter(userList, 'benutzername', objectList2AttrList(list, 'kuerzel'), true), 'rolle', data.roles.ADMIN, true) : []) : list ? list : []}
                                businessObject={type.businessObject}
                                valueProperty={type.selectProperty}
                                presentationProperty={type.selectProperty}
                                filter={type.filterBy && type.filterValue ? {attr: type.filterBy, value: type.filterValue} : null}
                                isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null)}
                                notNull={(typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject))}
                            />
                    )
                default:
                    if (key === 'name' || key === 'vorname' || key === 'email') {
                        return <InputByType key={index} passKey={index} type={type.type} asNumber={type.asNumber} readOnly={true} value={values[key]} maxChar={type.maxChar} name={key} presentation={presentation} setValue={(v) => setValues({...values, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], type?.semanticCheck?.compareTo ? values[type.semanticCheck.compareTo] : null)} notNull={false} />
                    }
                    return (
                        <InputByType
                            key={index}
                            passKey={index}
                            type={type.type}
                            asNumber={type.asNumber}
                            readOnly={checkReadOnly(key, readOnly, accessStatus)}
                            value={values[key]}
                            maxChar={type.maxChar}
                            name={key}
                            presentation={presentation}
                            setValue={(v) => setValues({...values, [key]: v})}
                            isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], type?.semanticCheck?.compareTo ? values[type.semanticCheck.compareTo] : null)}
                            notNull={(typeof type.notnull === 'boolean' && type.notnull) || (Array.isArray(type.notnull) && type.notnull.includes(businessObject))}
                        />
                    )
            }
        } else {
            return (
                <TextInput
                    key={index}
                    readOnly={checkReadOnly(key, readOnly, accessStatus)}
                    value={values[key]}
                    name={key}
                    presentation={presentation}
                    setValue={(v) => setValues({...values, [key]: v})}
                    isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, values[key], businessobject_data.types[key]?.semanticCheck?.compareTo ? values[businessobject_data.types[key].semanticCheck.compareTo] : null)}
                    notNull={businessobject_data.types[key] && ((typeof businessobject_data.types[key].notnull === 'boolean' && businessobject_data.types[key].notnull) || (Array.isArray(businessobject_data.types[key].notnull) && businessobject_data.types[key].notnull.includes(businessObject)))}
                />
            )
        }
    }

    //{(geschaeftsobjekt == 'kompetenz' | geschaeftsobjekt == 'qualifikation') ? <RowDefine values={values} field={geschaeftsobjekt} readOnly={readOnly} fieldData={businessobjectsList ? (geschaeftsobjekt == 'qualifikation' ? businessobjectsList.qualifikation : businessobjectsList.kompetenz) : undefined} setValues={setValues} tab={tab}/>
    if (Object.keys(values).length === 0 && values.constructor === Object) {
        return (
            <div className="content" id="consultant">
                <PflegenKopfzeile_Berater accessStatus={accessStatus} readOnly={true} businessObjectFunctions={{onDelete, onLock, onSave, onCreate, onCreateLock, onRelease}} setTab={setTab} tabs={tabs} tab={tab} />
                <div className="inner-content">
                    {errorMsg && (
                        <div>
                            {' '}
                            <br /> <strong className="error">{errorMsg}</strong>
                        </div>
                    )}
                    <div className="not-found">
                        <div>
                            Der Berater <b>{id}</b> wurde nicht gefunden.
                        </div>
                    </div>
                </div>
                <PflegenFusszeile accessStatus={accessStatus} modDate={'00.00.0000 00:00'} />
            </div>
        )
    } else {
        //Take undefined values from default
        return (
            <div className="content" id="consultant">
                <PflegenKopfzeile_Berater role={role} id={id} loggedInUsername={username} accessStatus={accessStatus} object={values} readOnly={readOnly} somethingChanges={somethingChanges(accessStatus, values, object, businessObject)} businessObjectFunctions={{onDelete, onLock, onSave, onCreate, onCreateLock, onRelease, onSavePM, onSavePMPrivate}} setTab={setTab} tabs={tabs} tab={tab} setWantedStatus={setWantedStatus} />
                <div className="inner-content">
                    {fieldDataError && !errorMsg && (
                        <div id="server-error">
                            <strong className="error">{fieldDataError}</strong>
                            <br />
                            <br />{' '}
                        </div>
                    )}
                    {errorMsg && (
                        <div>
                            <strong className="error">{errorMsg}</strong>
                            <br />{' '}
                        </div>
                    )}
                    {showDeletePopUp ? (
                        <PopUp
                            title="Sind Sie sich sicher?"
                            text={
                                <div>
                                    Sie möchten den Berater, <strong>{id}</strong> löschen
                                </div>
                            }
                            close={onDelete_sure}
                            abort={() => setShowDeletePopUp(false)}
                            closeText="Löschen"
                        />
                    ) : null}
                    {showLockPopUp ? (
                        <PopUp
                            title="Sind Sie sich sicher?"
                            text={
                                <div>
                                    Sie möchten den Berater,{' '}
                                    <strong>
                                        {id} {values.status === data.status.GESPERRT ? 'Entsperren' : 'Sperren'}
                                    </strong>
                                </div>
                            }
                            close={onLock_sure}
                            abort={() => setShowLockPopUp(false)}
                            closeText={values.status === data.status.GESPERRT ? 'Entsperren' : 'Sperren'}
                        />
                    ) : null}
                    {accessStatus === data.accessStatus.create && <h3>Neuen Berater anlegen</h3>}
                    {error && (
                        <div id="client-error">
                            {' '}
                            <br /> <small className="error">{error}</small>
                        </div>
                    )}
                    <HandleMainInputs fields={fields} tab={tab} businessObject={businessObject} returnInput={returnInput} />
                </div>

                <PflegenFusszeile accessStatus={accessStatus} modDate={specificObject ? specificObject.modDate : '00.00.0000, 00:00'} />
            </div>
        )
    }
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 *
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    object: state.consultants.specific,
    createdId: state.consultants.createdId,
    list: state.consultants.list,
    userList: state.users.list,
    errorMsg: state.businessobjects.error,
    successMsg: state.businessobjects.success,
    fieldData: state.fielddata.data,
    fieldDataError: state.fielddata.error,
    competenceData: state.competences.list,
    requestedFile4Download: state.consultants.requestedFile,
    pmCompetenceSuccess: state.competences.competencePMCompetenceRequest,
    pmQualificationSuccess: state.competences.competencePMQualificationRequest,

    ...ownProps,
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 *
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = (dispatch) => ({
    get: (id) => dispatch(sendGetConsultantRequest(id)),
    sendDelete: (id) => dispatch(sendDeleteConsultantRequest(id)),
    sendUpdate: (consultant) => dispatch(sendUpdateConsultantRequest(consultant)),
    sendCreate: (consultant) => dispatch(sendCreateConsultantRequest(consultant)),
    sendCreateFile: (id, file) => dispatch(sendCreateFileRequest(id, file)),
    sendUploadProfilePic: (id, file) => dispatch(sendFileUpload(id, file, true)),
    sendDeleteFile: (id, fileId) => dispatch(sendDeleteConsultantFileRequest(id, fileId)),
    sendFileDownload: (id, fileId) => dispatch(sendFileDownload(id, fileId, false)),
    sendProfilPicDownload: (id, fileId) => dispatch(sendFileDownload(id, fileId, true)),
    sendCompetenceCreateRequest: (competence) => dispatch(sendCreatePMCompetenceRequest(competence)),
    sendQualificationCreateRequest: (competence) => dispatch(sendCreatePMQualificationRequest(competence)),
    setfurtherSuccess: () => dispatch(setSuccess()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Pflegen_Berater)
